<div class="wrapper page container">

  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 class="float-left m-bottom-0">{{'faq.title' | translate}}</h3>
      <ng-container *ngIf="profile == 'SUPERADMIN'">
        <button type="button" [routerLink]="['./new/faq']" class="btn blue-bg header-button float-right">
          {{'nav.new-faq' | translate}}
        </button>
      </ng-container>
    </div>
  </div>

  <div class="card">
    <div class="w100 app-search">
      <div class="w800">
        <sp-filter-section [showCancelButton]="searchForm.dirty" (searchClicked)="prepareForSearch()" (cancelClicked)="prepareForCancel()">
          <form [formGroup]="searchForm">
            <div class="row">
              <sp-input-text class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.keyword" [label]="'advance-search.filter_type'" [placeholder]="'advance-search.filter_type'"></sp-input-text>
              <sp-input-language-select class="col-md-4 col-sm-6 mt-2" [control]="searchForm.controls.language" [label]="'general.language'" [placeholder]="'advance-search.select_language'"
                *ngIf="profile=='SUPERADMIN'"></sp-input-language-select>
            </div>
          </form>
        </sp-filter-section>
      </div>
    </div>
    <div class="table-margin">
      <ngx-datatable #dataTable class='material outer-table expandable data-table fixed-row' [columnMode]="'force'" [headerHeight]="0"
        [footerHeight]="70" [rowHeight]="'auto'" [scrollbarH]="true" [scrollbarV]="false" [rows]='rows' *ngIf="rows.length > 0"
        [limit]="tableRecordNumbers" (page)="onPage($event)" [messages]="my_messages">
        <ngx-datatable-row-detail #myDetailRow (toggle)="onDetailToggle($event)">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-row-detail-template>
            <div class="white text-wrap">
              <div class="col-md-12 inner-panel padding-inner-table">
                <div [innerHtml]="row.description | safe: 'html'"></div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-row-detail>
        <ngx-datatable-column name="Name">
          <ng-template let-rowIndex="rowIndex" let-row="row" ngx-datatable-cell-template>
            <div class="row no-gutters py-3">
              <div class="col-auto" style="width: 30px;">
                <h4 class="mb-0 text-right">{{row.position}})</h4>
              </div>
              <div class="col ml-1">
                <h4 class="text-wrap mb-0" title="{{row.title}}">{{row.title}}</h4>
                <div class="mt-1">
                  <ng-container *ngIf="row.permalink">
                    <a href={{getFaqPermalink(row)}} target="_blank">{{getFaqPermalink(row)}}</a>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column [width]="200" [resizeable]="false" [sortable]="false" [draggable]="false" [canAutoResize]="false">
          <ng-template let-row="row" let-expanded="expanded" ngx-datatable-cell-template>
            <div class="f-right">
              <button class="blue-bg details-button" title="Expand/Collapse Row" (click)="toggleExpandRow(row,expanded)">
                <span *ngIf="!expanded">{{'sale.see_details' | translate}}</span>
                <span *ngIf="expanded">{{'general.close' | translate}}</span>
              </button>
              <ng-container *ngIf="profile == 'SUPERADMIN'">
                <a href="#" class=" moredd dropdown-toggle nav-link blue-bg sec-butt" data-bs-toggle="dropdown" role="button"
                  aria-expanded="false">
                  <span class="caret"></span>
                </a>
                <ul class="dropdown-menu detail-dropdrown" role="menu">
                  <li class="nav-item">
                    <a class="nav-link edit" [routerLink]="['./edit/faq/'+row.id]">{{'faq.modificate' | translate}}</a>
                    <a class="nav-link edit action_enabled" (click)="onDelete(row)">{{'general.delete' | translate}}</a>
                  </li>
                </ul>
              </ng-container>
            </div>
          </ng-template>
        </ngx-datatable-column>
      </ngx-datatable>
      <div class="" *ngIf="rows.length == 0">
        <h4 class="a-center">{{ 'statistics.empty_table' | translate }}</h4>
      </div>
    </div>
  </div>

</div>
