<div class="container wrapper">
  <div class="row page-title">
    <div class="col-md-12 col-xs-12">
      <h3 *ngIf="!idFaq" class="float-left m-bottom-0">{{ 'insert.' + type | translate }}</h3>
      <h3 *ngIf="idFaq" class="float-left m-bottom-0">{{ 'insert.modify-' + type | translate }}</h3>
      <button type="button" class="btn btn-danger float-right button-style" (click)="navigationBack()">
        {{'insert.exit' | translate}}
      </button>
    </div>
  </div>
  <div class="row card">
    <div class="full-width width100">
      <div class="white-bg p-15">
        <div class="col-md-12">
          <div class="col-md-12">
            <form [formGroup]="form" (ngSubmit)="onSubmit()" class="full-width width100">
              <div class="row">
                <div class="col-md-4">
                  <div class="has-float-label">
                    <ng-select class="custom" placeholder="{{'insert.language' | translate}}*" formControlName="language" id="language"
                      [items]="languageList" bindLabel="text" bindValue="code">
                      <ng-template ng-label-tmp let-item="item" let-clear="clear">
                        <span (click)="clear(item)" aria-hidden="true" class="ng-value-icon left ng-star-inserted">×</span>
                        <span class="ng-value-label ng-star-inserted">
                          <img class="img-responsive mr-1" src="assets/img/country/{{item.code}}.png" style="max-width: 30px;" />
                          {{item.text}} - ({{item.code}})
                        </span>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                        <span class="ng-option-label ng-star-inserted">
                          <img class="img-responsive mr-1" src="assets/img/country/{{item.code}}.png" style="max-width: 30px;" />
                          {{item.text}} - ({{item.code}})
                        </span>
                      </ng-template>
                    </ng-select>
                  </div>
                </div>
                <div class="w-100"></div>
                <div class="col-md-12">
                  <div class="has-float-label">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="title" id="title">
                    <label class="label custom-label" for="title"> {{'insert.title' | translate}}* </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="has-float-label">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="tag" id="tag">
                    <label class="label custom-label" for="tag"> {{'insert.tag' | translate}}* </label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="has-float-label">
                    <input class="custom-float-label-input" type="number" placeholder=" " formControlName="position" id="position"
                      min="0" step="1">
                    <label class="label custom-label" for="position"> {{'insert.position' | translate}} </label>
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="has-float-label">
                    <input class="custom-float-label-input" type="text" placeholder=" " formControlName="permalink" id="permalink">
                    <label class="label custom-label" for="permalink"> {{'insert.permalink' | translate}} </label>
                  </div>
                </div>
                <div class="col-md-12">
                  <div style="margin: 20px 0px 10px 0px;">
                    <label class="label custom-label" for="description"> {{'insert.description' | translate}}* </label>
                    <div [ngxSummernote]="summernoteConfig" formControlName="description" id="description"></div>
                  </div>
                </div>

                <div class="g-button-position col-md-12">
                  <button class="btn btn-success save60" type="submit">
                    <span *ngIf="idFaq">{{'insert.modify1-'+type | translate }}</span>
                    <span *ngIf="!idFaq">{{'insert.enable_user' | translate}}</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>