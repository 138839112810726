import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { AppService } from '../../../core/services/base.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { LoggingService } from '../../../core/services/log.service';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../../core/translator/translator.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { FaqService } from '../../../services/faq.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqComponent implements OnInit, OnDestroy {
  language: any;
  permalink: any;
  faqList: any = [];
  @ViewChild('dataTable') table: any;
  rows: any[] = [];
  timeout: any;

  profile : any;

  tableRecordNumbers:number;

  my_messages = {
    'emptyMessage': '',
    'totalMessage': ''
  };

  searchForm: FormGroup;

  private languageSub: Subscription;

  constructor(private router: Router,
    private route: ActivatedRoute,
    public appService: AppService,
    private logService: LoggingService,
    private translate: TranslateService,
    public settings: SettingsService,
    private faqService: FaqService,
    private formBuilder: FormBuilder,
    private translator: TranslatorService) {
      this.profile = localStorage.getItem('profile')

      this.tableRecordNumbers = parseInt(localStorage.getItem('tableRecordNumbers'));
      // Overrides default total/empty messages
      translate.stream("datatable_message.lista_totali_righe").subscribe((res : string ) => this.my_messages.totalMessage = res );
      translate.stream("datatable_message.lista_vuota_righe").subscribe((res : string ) => this.my_messages.emptyMessage = res);

      this.searchForm = this.formBuilder.group({
        keyword: [null],
        language: [null, this.profile != 'SUPERADMIN' ? Validators.required : null]
      });
  }

  ngOnInit(): void {
    this.language = this.route.snapshot.params['language'];
    this.permalink = this.route.snapshot.params['permalink'];

    const { language, permalink } = history.state;

    if (this.language && this.permalink) {
      this.router.navigate(['/faq'], { state: { language: this.language, permalink: this.permalink, navigationUrl: this.router.url } })
    } else {
      if (language && permalink) {
        this.getAllFaqs('language=' + language + '&permalink=' + permalink, true);

        this.translator.useLanguage(language.toUpperCase());

        if (this.profile != 'SUPERADMIN') {
          this.searchForm.get('language').setValue(this.translator.getLanguage() || null);
        }
      } else {
        if (this.profile != 'SUPERADMIN') {
          this.searchForm.get('language').setValue(this.translator.getLanguage() || null);
        }

        this.prepareForSearch();
      }

      this.languageSub = this.translator.onLngChange.subscribe((lng) => {
        if (this.profile != 'SUPERADMIN') {
          this.searchForm.get('language').setValue(lng || null);
        }
      });

      this.searchForm.get('language').valueChanges.subscribe(val => {
        if (val) {
          this.prepareForSearch();
        }
      });
    }
  }
  
  ngOnDestroy() {
    if (this.languageSub) {
      this.languageSub.unsubscribe();
    }
  }

  getAllFaqs(queryParams = null, autoexpand = false) {
    this.faqService.getAllFaqs(queryParams).subscribe((res: any) => {
      this.faqList = res.results;
      this.rows = this.faqList;

      if (this.rows) {
        if (autoexpand) {
          setTimeout(() => {
            this.rows.forEach(row => {
              this.table.rowDetail.toggleExpandRow(row);
            })
          });
        }
      }
    })
  }

  getFaqPermalink(row) {
    return window.location.origin + '/faq/permalink/' + row.language.toLowerCase() + '/' + row.permalink;
  }

  onPage(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
    }, 100);
  }

  toggleExpandRow(row, expanded) {
    this.table.rowDetail.toggleExpandRow(row);
  }

  onDetailToggle(event) {
  }

  prepareForSearch() {
    let queryParamsSearch = "";
    for (let key in this.searchForm.controls) {
      if (this.searchForm.controls[key].value) {
        if (Array.isArray(this.searchForm.controls[key].value)) {
          if (!!this.searchForm.controls[key].value.length) {
            if (queryParamsSearch) {
              queryParamsSearch += "&"
            }
            queryParamsSearch += key + "=" + this.searchForm.controls[key].value.join(',')
          }
        } else {
          if (queryParamsSearch) {
            queryParamsSearch += "&"
          }
          queryParamsSearch += key + "=" + this.searchForm.controls[key].value
        }
      }
    }

    this.getAllFaqs(queryParamsSearch)
    if( this.table ) {
      this.table.offset = 0;
    }
  }

  prepareForCancel() {
    if (this.profile != 'SUPERADMIN') {
      this.searchForm.reset({
        language: this.translator.getLanguage() || null
      }, { emitEvent: false });
    } else {
      this.searchForm.reset({
        language: null
      }, { emitEvent: false });
    }
    this.searchForm.markAsPristine();
    this.prepareForSearch();
  }

  onDelete(row) {
    swal.fire({
      icon: "warning",
      title: this.translate.instant('faq.swal.warning_delete_title'),
      text: this.translate.instant('faq.swal.warning_delete_text'),
      confirmButtonText: this.translate.instant('faq.swal.confirm_delete_text'),
      confirmButtonColor: "#ed2d2d",
      showCancelButton : true,
      cancelButtonText: this.translate.instant('general.cancel')
    }).then((result) => {
      if( result.value ) {
        swal.fire({
          title: '',
          didOpen: (() => swal.showLoading()),
          allowEscapeKey: false,
          allowOutsideClick: false,
        });
        this.faqService.deleteFaq(row.id).subscribe((resp) => {
          if( resp.outcome.success ) {
            swal.fire( this.translate.instant('swal.title_Success') , this.translate.instant('swal.content_Success') , "success" ).then((result) => {
              if (result.value) {
                this.prepareForSearch();
              }
            })
          }
        })
      } else {
        swal.close();
      }
    })
  }
}
