import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ColorsService } from '../../../shared/colors/colors.service';
import { SettingsService } from '../../../core/settings/settings.service';
import { HttpClient } from '@angular/common/http';
import { FaqService } from '../../../services/faq.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TranslatorService } from '../../../core/translator/translator.service';
import { RouterExtService } from '../../../core/services/router.ext.service';
import { Subscription } from 'rxjs';
import swal from 'sweetalert2';

@Component({
  selector: 'app-faq-manage',
  templateUrl: './faq-manage.component.html',
  styleUrls: ['./faq-manage.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FaqManageComponent implements OnInit {
  profile: any;
  idFaq: any;
  type: any;
  pageType: any;
  faq: any;
  languageList = this.translator.getAvailableLanguages();

  form = this.formBuilder.group({
    title: ['', Validators.required],
    tag: [null, Validators.required],
    description: [null, Validators.required],
    permalink: [null],
    position: [null],
    language: [null, Validators.required]
  });

  summernoteDefaultConfig = {
    placeholder: '',
    tabsize: 2,
    height: '200px',
    toolbar: [
      ['misc', ['undo', 'redo']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'color']],
      ['para', ['ul', 'ol', 'paragraph']],
      ['insert', ['link', 'hr']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times'],
    followingToolbar: false
  }

  summernoteConfig = {
    ...this.summernoteDefaultConfig, 
    lang: this.translator.getSummernoteLanguage()
  }

  private languageSub: Subscription;

  constructor(private location: Location,
    private translate: TranslateService,
    private translator: TranslatorService,
    private formBuilder: FormBuilder,
    public colors: ColorsService,
    public http: HttpClient,
    public settings: SettingsService,
    private faqService: FaqService,
    private route: ActivatedRoute,
    private router: Router,
    private routerExtService: RouterExtService
  ) {}

  ngOnInit() {
    this.pageType = this.route.snapshot.params['pageType'];
    this.type = this.route.snapshot.params['type'];
    this.idFaq = this.route.snapshot.params['id'];
    this.profile = localStorage.getItem("profile");

    if (this.idFaq != null) {
      this.getFaq(this.idFaq);
      console.log('this.idFaq != null - ID: ' + this.idFaq)
    }

    this.languageSub = this.translator.onLngChange.subscribe((lng) => {
      if (lng) {
        this.summernoteConfig = {
          ...this.summernoteDefaultConfig,
          lang: this.translator.getSummernoteLanguage(lng)
        }
      }
    });
  }
  
  ngOnDestroy() {
    this.languageSub.unsubscribe();
  }

  getFaq(idFaq) {
    this.faqService.getFaq(idFaq).subscribe((res:any)=> {
      this.faq = res;
      console.log( 'call getFaq' )
      if (this.faq.id) {
        this.setFormValue(this.faq);
      }
    })
  }

  setFormValue(faq) {
    if (faq) {
      this.form.setValue({
        title: faq.title,
        tag: faq.tag,
        description: faq.description,
        permalink: faq.permalink,
        position: faq.position,
        language: faq.language
      });
    }
  }

  onSubmit() {
    for (let i in this.form.controls) {
      this.form.controls[i].markAsTouched();
    }
    if (this.form.valid) {
      this.save(this.form.value)
    }
  }

  save(faq) {
    swal.fire({
      title: '',
      didOpen: (() => swal.showLoading()),
      allowEscapeKey: false,
      allowOutsideClick: false,
    });
    if (!this.idFaq) {
      this.faqService.insertFaq(faq).subscribe((response: any) => {
        if (response.outcome && response.outcome.success === true) {
          swal.fire(this.translate.instant('faq.swal_created_ok'), "" , "success").then((result) => {
            if (result.value) {
              this.navigationBack();
            }
          });
        }
        else {
          swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      }, (error) => {
        swal.fire("Error", error, "error");
      })
    }
    else {
      faq['id'] = this.idFaq
      this.faqService.updateFaq(faq).subscribe((response: any) => {
        if (response.outcome && response.outcome.success === true) {
          swal.fire(this.translate.instant('faq.swal_update_ok'), "" , "success").then((result) => {
            if (result.value) {
              this.navigationBack();
            }
          });
        }
        else {
          swal.fire("Error", this.settings.manageErrorMsg(response.outcome), "error");
        }
      },(error) => {
        swal.fire("Error", error, "error");
      })
    }
  }

  navigationBack() {
    if (!this.routerExtService.getPreviousUrl() ||
      this.routerExtService.getPreviousUrl() == this.router.url ||
      this.routerExtService.getPreviousUrl().startsWith('/login')) {
      this.router.navigateByUrl('/faq');
    } else {
      this.location.back();
    }
  }
}