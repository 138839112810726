/* src/app/routes/faq/faq-manage/faq-manage.component.scss */
.tittle {
  font-family: "Source Sans Pro", sans-serif;
  color: #4d4f5b;
  font-size: 30px;
}
.inline-block-display {
  display: inline-block;
  padding-right: 0px;
}
.body-position {
  padding-top: 60px;
}
.dropdown-color {
  background-color: white;
}
.padding-top {
  padding-top: 40px;
}
.padding-right {
  padding-right: 40px;
  padding-top: 30px;
}
.no-padding-left {
  padding-left: 0px;
}
.width100 {
  width: 100%;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #e6f2ff;
  border-radius: 2px;
  color: black;
  padding: 2px 5px;
  margin: 0 0.4375em 0.4375em 0;
}
.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon {
  color: black !important;
}
.display-n {
  display: none;
}
/*# sourceMappingURL=faq-manage.component.css.map */
