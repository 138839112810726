import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { LoggingService } from '../core/services/log.service';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SettingsService } from '../core/settings/settings.service';


@Injectable({
  providedIn: 'root'
})
export class FaqService {
  public paging = true;
  public itemsPerPage = environment.rows; // server pagination
  public page: number = 1;
  public numPages = 1;
  public totalItems = 1;

  constructor(private http: HttpClient,
    private logger: LoggingService,
    private router: Router,
    private settings: SettingsService) { }


  getAllFaqs(queryParams = null) {
    var path = environment.restBaseUrl + "/faq/getAll"
    if (queryParams) {
      path += "?" + queryParams
    }
    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];
        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  getFaq(idFaq) {
    var path = environment.restBaseUrl + "/faq/" + idFaq

    return this.http.get<any[]>(path, this.getRequestOptionArgs()).pipe(map(
      (response: HttpResponse<any>) => {
        const outcome = response['outcome'];
        const dataResponse = response['data'];
        //this.totalItems = dataResponse['total'];

        if (outcome.success === true) {
          this.logger.log("Service:", "SUCCESS", 200);
          return dataResponse;
        } else {
          this.logger.log("Service:", "FAILURE", 200);
          outcome.message = this.settings.manageErrorMsg(outcome);
          return outcome;
        }

      }
    ), catchError((errorResponse: HttpErrorResponse) => {
        let res: any = errorResponse.error;
        if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
          this.settings.sessionExpired();
        }
        return throwError(errorResponse.error);
      }
    ));
  }

  insertFaq(faq){
    var path = environment.restBaseUrl + "/faq";
    return this.http
      .post<any[]>(path, faq, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
            const res: any = response;

            if (res.outcome.success === true) {
              this.logger.log("insertFaq", " SUCCESS", 200);
              return res;
            } else {
              this.logger.log("insertFaq", " FAILURE", 200);
              return res;
            }
          }
        ), catchError((errorResponse: HttpErrorResponse) => {
          let res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        }
      ));
  }

  updateFaq(faq) {
    var path = environment.restBaseUrl + "/faq";
    return this.http
      .put<any[]>(path, faq, this.getRequestOptionArgs())
      .pipe(map(
        (response: HttpResponse<any>) => {
            const res: any = response;

            if (res.outcome.success === true) {
              this.logger.log("updateFaq", " SUCCESS", 200);
              return res;
            } else {
              this.logger.log("updateFaq", " FAILURE", 200);
              return res;
            }
          }
        ), catchError((errorResponse: HttpErrorResponse) => {
          let res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        }
      ));
  }

  deleteFaq(idFaq) {
    var path = environment.restBaseUrl + '/faq/' + idFaq;
    return this.http
      .delete<any[]>(path, this.getRequestOptionArgs() )
      .pipe(map(
        (response: any) => {
          const outcome = response['outcome'];
          const dataResponse = response['data'];
          if (outcome.success === true) {
            this.logger.log("Service:", "SUCCESS", 200);
            return response;
          } else {
            this.logger.log("Service:", "FAILURE", 200);
            outcome.message = this.settings.manageErrorMsg(outcome);
            return outcome;
          }
        }
      ), catchError((errorResponse: HttpErrorResponse) => {
          this.logger.log("Error Response:", errorResponse, 200);
          let res: any = errorResponse.error;
          if (res && res.outcome && (res.outcome.code === "0005" || res.outcome.code === "0007")) {
            this.settings.sessionExpired();
          }
          return throwError(errorResponse.error);
        }
      ));
  }

  getRequestOptionArgs(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': localStorage.getItem("token") || ''
      })
    };
    return httpOptions;
  }

  getRequestOptionArgsNull(): any {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return httpOptions;
  }
}
