import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { SharedModule } from '../../shared/shared.module';
import { FaqComponent } from './faq/faq.component';
import { FaqManageComponent } from './faq-manage/faq-manage.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';

const routes: Routes = [
  { path: '', component: FaqComponent },
  { path: 'permalink/:language/:permalink', component: FaqComponent },
  { path: 'new/:type', component: FaqManageComponent },
  { path: 'edit/:type/:id', component: FaqManageComponent },
];

@NgModule({
  imports: [
    SharedModule,
    RouterModule.forChild(routes),
    NgxDatatableModule,
    NgSelectModule,
    ModalModule.forRoot()
  ],
  providers: [
    BsDropdownDirective
  ],
  declarations: [
    FaqComponent,
    FaqManageComponent,
  ],
  exports: [
    RouterModule
  ]
})

export class FaqModule { }
